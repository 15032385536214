import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout/layout";
import config from "../../data/SiteConfig";
import Img from "gatsby-image";
import {
  Container,
  Content,
  Section,
  Columns,
  Column,
  Tag,
  Breadcrumb,
  BreadcrumbItem,
  Icon,
} from "bloomer";

export default class ProjectTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: 0,
      product: props.data.product,
    };
  }

  buyOnStripeButton() {
    if (this.state.product.frontmatter.square_checkout) {
      return (
        <React.Fragment>
          <a
            class="button is-primary is-fullwidth is-large"
            href={this.state.product.frontmatter.square_checkout}
          >
            <span class="icon">
              <i class="fas fa-shopping-cart"></i>
            </span>
            <span>Buy audio CD</span>
          </a>
        </React.Fragment>
      );
    }
    return null;
  }

  render() {
    const product = this.state.product;

    return (
      <Layout>
        <Helmet>
          <title>{`Shop | ${product.frontmatter.title} - ${product.fields.id}`}</title>
        </Helmet>
        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/shop/">Shop</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <a>{product.fields.id.toUpperCase()}</a>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <Columns className="is-vcentered-desktop">
              <Column isSize={{ mobile: 12, tablet: 4, widescreen: 6 }}>
                <div class="product-images">
                  <div class="product-images-display">
                    {product.frontmatter.images.map((image, index) => (
                      <Img
                        fluid={image.childImageSharp.large}
                        className={
                          this.state.selectedImage === index ? "active" : ""
                        }
                      />
                    ))}
                    {product.frontmatter.tag ? (
                      <div class="ribbon-wrapper">
                        <div
                          className={`ribbon ${product.frontmatter.tag.type}`}
                        >
                          {product.frontmatter.tag.text}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <ul class="product-images-thumbnail-list">
                    {product.frontmatter.images.map((image, index) => (
                      <li
                        className={
                          this.state.selectedImage === index ? "active" : ""
                        }
                        onClick={() => {
                          this.setState({ selectedImage: index });
                        }}
                      >
                        <img
                          src={image.childImageSharp.thumbnail.src}
                          width="75"
                          height="75"
                          alt=""
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </Column>
              <Column>
                <div className="product-info-container">
                  <div class="leveller">
                    <p class="has-text-centered">
                      <Tag isColor="light" isSize="small">
                        {product.fields.id.toUpperCase()}
                      </Tag>
                    </p>
                    <h1 class="title is-1 has-text-centered">
                      {product.frontmatter.title}
                    </h1>
                    <p class="subtitle is-4 has-text-centered has-text-grey">
                      {product.frontmatter.teaser}
                    </p>
                  </div>
                  <div class="leveller">
                    {this.buyOnStripeButton()}
                    {product.frontmatter.links && product.frontmatter.square_checkout ? (
                      <div
                        class="is-divider"
                        data-content="OR LISTEN ONLINE"
                      ></div>) : null}
                    <div class="columns is-multiline is-centered">
                      {product.frontmatter.links
                        ? product.frontmatter.links.map((link) => (
                            <Column
                              isSize={{
                                mobile: 12,
                                tablet: parseInt(link.width),
                              }}
                            >
                              <a
                                class={`button is-fullwidth ${
                                  link.style ? link.style : "is-dark"
                                }`}
                                href={link.url}
                                target="_blank"
                              >
                                <span class="icon">
                                  <i class={link.icon}></i>
                                </span>
                                <span>{link.label}</span>
                              </a>
                            </Column>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </Column>
            </Columns>
          </Container>
        </Section>

        {product.html !== "" ? (
          <Section>
            <Container>
              <Columns isCentered>
                <Column isSize={{ mobile: 12, desktop: 8 }}>
                  <Content
                    renderAs="article"
                    dangerouslySetInnerHTML={{ __html: product.html }}
                  />
                </Column>
              </Columns>
            </Container>
          </Section>
        ) : null}
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query RecordingByCatalogueNo($slug: String!) {
    product: markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        id
      }
      frontmatter {
        id
        title
        teaser
        price
        stripe_sku
        square_checkout
        images {
          childImageSharp {
            large: fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
            thumbnail: fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        links {
          label
          url
          icon
          width
        }
      }
      html
    }
  }
`;
